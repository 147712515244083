import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes
} from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import HomePage from '../pages/HomePage';
import ResponsiveContainer from './ResponsiveContainer';
import { AuthProvider } from '../providers/AuthProvider';
import ScrollTo from './ScrollTo';
import { Toaster } from 'react-hot-toast';

import Privacy from '../pages/Privacy';
import Settings from '../pages/Settings';
import Terms from '../pages/Terms';
import Dashboard from '../pages/Dashboard';
import Game from '../pages/Game';
import NotFound from '../pages/NotFound';

export default function PageRouter() {
    const ProtectedRoute: React.FC<React.PropsWithChildren> = () => {
        const { authenticated } = useAuth();

        return (
            authenticated ? <Outlet /> : <Navigate to='/' />
        )
    };

    return (
        <BrowserRouter>
            <AuthProvider>
                <ResponsiveContainer>
                    <ScrollTo>
                        <Routes>
                            <Route element={<ProtectedRoute />}>
                                <Route path='/settings' element={<Settings />} />
                                <Route path='/dashboard' element={<Dashboard />} />
                                <Route path='/play' element={<Game />} />
                            </Route>
                            <Route path='/privacy' element={<Privacy />} />
                            <Route path='/terms' element={<Terms />} />
                            <Route path='/' element={<HomePage />} />
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </ScrollTo>
                </ResponsiveContainer>
            </AuthProvider>
            <Toaster
                position="top-right"
                toastOptions={{
                    className: 'toastNotification',
                    style: {
                        background: '#2D2D42',
                        border: '1px solid #E3DAC9',
                        padding: '16px',
                        color: '#E3DAC9',
                    },
                }}

            />
        </BrowserRouter>
    )
}
