import React from 'react';

function Notifications() {
    return (
        <>
            <h1>Notifications</h1>
        </>
    )
}

export default Notifications;
