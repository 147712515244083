import React from 'react';

function SettingsPrivacy() {
    return (
        <>
            <h1>Security &amp; Privacy</h1>
        </>
    )
}

export default SettingsPrivacy;
