import { toast } from 'react-hot-toast';
import {
    Button,
    Item,
} from 'semantic-ui-react';

export interface TwitchUser {
    id: number,
    username: string,
    display: string,
    avatar: string,
}

class Knucklebones {
    private static instance: Knucklebones;

    private constructor() { }

    public static getInstance(): Knucklebones {
        if (!Knucklebones.instance) {
            Knucklebones.instance = new Knucklebones();
        }

        return Knucklebones.instance;
    }

    public ShowInvite(user: TwitchUser) {
        const headers: string[] = [
            "A challenger appears!",
            "Prepare for battle, a challenger awaits!",
            "Incoming challenge! Get ready!",
            "You've been challenged!",
            "Rise to the challenge!",
            "A rival has arrived!",
            "A challenger seeks your skills!",
            "A gauntlet has been thrown!",
            "Show them what you've got!",
            "A challenger awaits!",
            "Your skills have been challenged!",
        ];

        const header: string = headers[Math.floor(Math.random() * headers.length)];

        toast((t) => (
            <Item.Group>
                <Item>
                    <Item.Image size='tiny' avatar src={user?.avatar} />

                    <Item.Content>
                        <Item.Header>{header}</Item.Header>
                        <Item.Description>{user?.display} has challenged you to a game of Knucklebones.</Item.Description>
                        <Item.Extra>
                            <Button onClick={() => toast.dismiss(t.id)} floated='right'>Ignore</Button>
                            <Button onClick={() => document.location = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'} floated='right'>Accept</Button>
                        </Item.Extra>
                    </Item.Content>
                </Item>
            </Item.Group>
        ))
    }

    public SelectBotDifficulty() {
    }
}

export default Knucklebones;