import React from 'react';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const ScrollTo: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {

    if (location.hash !== "") {
      const anchor = document.querySelector(location.hash);
      anchor?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      if (location.pathname === document.location.pathname) {
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
        })
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [location]);

  return (
    <>
      {children}
    </>
  );
}

export default ScrollTo;