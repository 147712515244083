import React, { useState } from "react";
import { MenuItemProps } from "semantic-ui-react";

import { Media } from "./Media";
import NavBarMobile from "./NavBarMobile";
import NavBarDesktop from "./NavBarDesktop";

interface Props extends React.PropsWithChildren {
    menuItems: Array<MenuItemProps>,
}

const NavBar: React.FC<Props> = props => {
    const [visible, setVisibility] = useState(false);

    const {
        children,
        menuItems,
    } = props;

    return (
        <div>
            <Media at="mobile">
                <NavBarMobile
                    menuItems={menuItems}
                    setVisibility={setVisibility}
                    visible={visible}
                >
                    {children}
                </NavBarMobile>
            </Media>
            <Media greaterThan="mobile">
                <NavBarDesktop menuItems={menuItems} />
                {children}
            </Media>
        </div>
    );
};

export default NavBar;