import React from 'react';
import {
    Container,
    Grid,
    Image,
    Label,
} from 'semantic-ui-react'
import { useDiceTray } from 'react-dicetray';
import './Game.css';

function Game() {
    const heroDiceTray = useDiceTray({
        scale: 6,
        theme: "cotl",
        gravity: 5,
        throwForce: 3
    });

    return (
        <>
            <div style={{ position: 'relative', left: 0, right: 0, width: '100%', height: '620px', display: 'contents', verticalAlign: 'middle' }}>
                <Image src='/images/bg.png' style={{ objectFit: 'cover', height: '620px', minWidth: '100%' }} />
                <div style={{ background: 'linear-gradient(180deg, rgba(32,30,42,0.74) 0%, rgba(32,30,42,0.84) 60%, rgba(32,30,42,1) 90%)', zIndex: 0, position: 'absolute', inset: '0px', height: '670px' }} />
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div style={{ position: 'absolute', left: 0, top: 50, height: '400px', width: '100%' }}>
                    <Container>
                        <Grid columns={2} celled container style={{ minHeight: 400 }}>
                            <Grid.Row>
                                <Grid.Column>
                                    <center>
                                        Player 1
                                        <Grid columns={3} celled centered style={{ width: 180 }} className="playarea">
                                            <Grid.Row style={{ height: 170 }}>
                                                <Grid.Column>
                                                    <div data-value="1" className="dice double">
                                                        <Label floating circular size="mini" style={{ marginTop: '12px !important', marginLeft: '-20px !important' }} color='grey'>
                                                            x2
                                                        </Label>
                                                    </div>
                                                    <div data-value="2" className="dice" />
                                                    <div data-value="1" className="dice double" />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <div className="dice4" />
                                                    <div className="dice5" />
                                                    <div className="dice6" />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <div className="dice3 triple" />
                                                    <div className="dice3 triple" />
                                                    <div className="dice3 triple" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <br />
                                        <Grid columns={3} celled centered style={{ width: 180 }}>
                                            <Grid.Row style={{ height: 60 }}>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column></Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row style={{ height: 60 }}>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column></Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row style={{ height: 60 }}>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column></Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        Player 2
                                    </center>
                                </Grid.Column>
                                <Grid.Column onClick={() => heroDiceTray.rollDice('1d6')}>
                                    {heroDiceTray.diceTray}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Game;
