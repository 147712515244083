export const diceThemes = [
    {
        key: 'Copper Patina',
        text: 'Copper Patina',
        value: 'blueGreenMetal',
    },
    {
        key: 'Cult of the Lamb',
        text: 'Cult of the Lamb',
        value: 'cotl',
    },
    {
        key: 'Dice of Rolling',
        text: 'Dice of Rolling',
        value: 'diceOfRolling',
    },
    {
        key: 'Gemstone',
        text: 'Gemstone',
        value: 'gemstone',
        themeable: true,
    },
    {
        key: 'Marble Gemstone',
        text: 'Marble Gemstone',
        value: 'gemstoneMarble',
    },
    {
        key: 'Plain',
        text: 'Plain',
        value: 'default',
        themeable: true,
    },
    {
        key: 'Rock',
        text: 'Rock',
        value: 'rock',
        themeable: true,
    },
    {
        key: 'Rust',
        text: 'Rust',
        value: 'rust',
        themeable: true,
    },
    {
        key: 'Smooth',
        text: 'Smooth',
        value: 'smooth',
        themeable: true,
    },
    {
        key: 'Wooden',
        text: 'Wooden',
        value: 'wooden',
    },
];
