import React from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    Container,
    Dimmer,
    Header,
} from 'semantic-ui-react';
import { useAuth } from '../providers/AuthProvider';
import Knucklebones from '../lib/knucklebones';


function Dashboard() {
    const { isWhitelisted } = useAuth();

    const whitelistedItems = [
        {
            as: Link,
            header: 'Another Player',
            // description: 'Challenge another Twitch user directly on the site.',
            description:
                <>
                    <Dimmer active>
                        <Header as='h3' inverted>
                            Not available yet!
                        </Header>
                    </Dimmer>
                    <Card.Description>Challenge another Twitch user directly on the site.</Card.Description>
                </>,
            disabled: true,
            className: 'cardButton',
        },
        {
            as: Link,
            header: 'Twitch Chat',
            // to: '/play',
            // description: 'Play against Twitch Chat on your stream.',
            description:
                <>
                    <Dimmer active>
                        <Header as='h3' inverted>
                            Not available yet!
                        </Header>
                    </Dimmer>
                    <Card.Description>Play against Twitch Chat on your stream.</Card.Description>
                </>,
            className: 'cardButton',
        },
        {
            as: Link,
            header: 'A Bot',
            onClick: () => {
                Knucklebones.getInstance().SelectBotDifficulty()
            },
            // to: '/play',
            description: 'Play against an A.I. opponent.',
            className: 'cardButton',
        },
    ];

    const everyoneItems = [
        {
            header: 'Another Player',
            description:
                <>
                    <Dimmer active>
                        <Header as='h3' inverted>
                            Not available yet!
                        </Header>
                    </Dimmer>
                    <Card.Description>Challenge another Twitch user directly on the site.</Card.Description>
                </>,
            disabled: true,
            className: 'cardButton',
        },
        {
            header: 'Twitch Chat',
            description:
                <>
                    <Dimmer active>
                        <Header as='h3' inverted>
                            Not available yet!
                        </Header>
                    </Dimmer>
                    <Card.Description>Play against Twitch Chat on your stream.</Card.Description>
                </>,
            className: 'cardButton',
        },
        {
            header: 'A Bot',
            description:
                <>
                    <Dimmer active>
                        <Header as='h3' inverted>
                            Not available yet!
                        </Header>
                    </Dimmer>
                    <Card.Description>Play against an A.I. opponent.</Card.Description>
                </>,
            className: 'cardButton',
        },
    ];


    if (isWhitelisted()) {
        return (
            <>
                <Container style={{ marginTop: 100 }}>
                    <Header as='h1'>Start a New Game:</Header>
                    <br />
                    <Card.Group centered items={whitelistedItems} />
                </Container>
                <br /><br />
                <br /><br />
            </>
        )
    }

    return (
        <>
            <Container style={{ marginTop: 100 }}>
                <Header as='h1'>Start a New Game:</Header>
                <br />
                <Card.Group centered items={everyoneItems} />
            </Container>
            <br /><br />
            <br /><br />
        </>
    )
}

export default Dashboard;
