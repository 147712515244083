import React from 'react';
import {
    Container,
    Grid,
    Header,
    Image,
} from 'semantic-ui-react'
import './Game.css';

function NotFound() {
    return (
        <>
            <div style={{ position: 'relative', left: 0, right: 0, width: '100%', minHeight: '1050px', verticalAlign: 'middle' }}>
                <Image src='/images/bg.png' style={{ objectFit: 'cover', height: '620px', minWidth: '100%' }} />
                <div style={{ background: 'linear-gradient(180deg, rgba(32,30,42,0.74) 0%, rgba(32,30,42,0.84) 60%, rgba(32,30,42,1) 90%)', zIndex: 0, position: 'absolute', inset: '0px', height: '670px' }} />
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div style={{ position: 'absolute', left: 0, top: 150, height: '100vh', width: '100%' }}>
                    <Container>
                        <Grid columns={2} container>
                            <Grid.Row>
                                <Grid.Column style={{ textAlign: 'right' }}>
                                    <Header as='h1'>Echoes of Solitude</Header>
                                    <p>
                                        In the depths of the woods, a lone fox strays,<br />
                                        Seeking an opponent for a playful bout.<br />
                                        But silence persists in these secluded ways.<br />
                                    </p>
                                    <p>
                                        Amidst the trees' embrace, the fox surveys,<br />
                                        Yearning for a companion to dance about,<br />
                                        In the depths of the woods, a lone fox strays.<br />
                                    </p>
                                    <p>
                                        Eyes keen, ears alert, he hopes for a chase,<br />
                                        A rival to challenge, without a doubt.<br />
                                        But silence persists in these secluded ways.<br />
                                    </p>
                                    <p>
                                        He bounds with grace, through the mystic maze,<br />
                                        Whispering leaves and shadows cast about,<br />
                                        In the depths of the woods, a lone fox strays.<br />
                                    </p>
                                    <p>
                                        Echoes of laughter from bygone days,<br />
                                        Yet no playful adversary can be found, no shout.<br />
                                        But silence persists in these secluded ways.<br />
                                    </p>
                                    <p>
                                        As dusk descends, the fox's spirit sways,<br />
                                        Accepting solitude, he wanders without,<br />
                                        In the depths of the woods, a lone fox strays.<br />
                                        But silence persists in these secluded ways.<br />
                                    </p>
                                </Grid.Column>
                                <Grid.Column>
                                    <Image src="/images/lost.png" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Container>
                </div>
            </div>
        </>
    )
}

export default NotFound;
