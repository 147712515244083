import React, { useEffect } from 'react';
import {
    Button,
} from 'semantic-ui-react';
import { useDiceTray } from 'react-dicetray';

interface Props extends React.PropsWithChildren {
    color: string,
    theme: string,
}

const DicePreview: React.FC<Props> = props => {
    const {
        color,
        theme
    } = props;

    const previewDiceTray = useDiceTray({
        scale: 18,
        theme: theme,
        gravity: 5,
        throwForce: 3,
        themeColor: color,
    });

    useEffect(() => {
        previewDiceTray.updateConfig({
            scale: 18,
            theme: theme,
            gravity: 5,
            throwForce: 3,
            themeColor: color,
        })
    }, [theme, color, previewDiceTray])

    return (
        <>
            {previewDiceTray.diceTray}
            <Button style={{ position: 'absolute', top: 0, right: 11, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }} onClick={() => previewDiceTray.rollDice('2d6')}>Roll Dice</Button>
        </>
    )
}

export default DicePreview;
