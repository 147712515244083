import React from "react";
import { mediaStyles, MediaContextProvider } from "./Media";
import NavBar from "./NavBar";
import {
    Container,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Segment,
} from "semantic-ui-react";
import {
    Link,
    NavLink
} from "react-router-dom";

const menuItems = [
    { as: Link, to: '/', content: 'Home', key: 'home' },
    { as: Link, to: { pathname: '/', hash: '#about' }, content: 'About', key: 'about' },
    { as: Link, to: { pathname: '/', hash: '#how-to-play' }, content: 'How to Play', key: 'how' }
];

const ResponsiveContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
    const scrollToTop = () => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <>
            <style>{mediaStyles}</style>
            <MediaContextProvider>
                <NavBar menuItems={menuItems}>
                    {children}
                </NavBar>
            </MediaContextProvider>
            <br /><br />
            <Segment inverted vertical style={{ padding: '2em 0em 3em 0em' }} className='footer'>
                <Container>
                    <center>
                        <Icon name="arrow alternate circle up outline" size="big" style={{ paddingBottom: '1.25em', cursor: 'pointer' }} onClick={scrollToTop} /><br />
                        Back to Top
                    </center>
                    <br /><br />
                    <Grid divided inverted stackable>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <List link inverted>
                                    <List.Item as={Link} to='/#about'>About</List.Item>
                                    <List.Item as={Link} to='/#how-to-play'>How to Play</List.Item>
                                    <List.Item as={Link} to='/premium'>Premium</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <List link inverted>
                                    <List.Item as={NavLink} to='/privacy'>Privacy Policy</List.Item>
                                    <List.Item as={NavLink} to='/terms'>Terms of Service</List.Item>
                                    <List.Item as='a' href='https://discord.gg/ZUaTdDv63t'>Discord</List.Item>

                                </List>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <Header as='h4' inverted>
                                    <Image src="/logo192.png" />
                                    Knucklebones.tv
                                </Header>
                                <p>
                                    Play Knucklebones against other online opponents or via Twitch chat.
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
        </>
    );
}

export default ResponsiveContainer;