import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useSocket } from "../hooks/useSocket";
import { Dimmer, Loader } from "semantic-ui-react";

type IauthContext = {
    authenticated: boolean,
    login: () => Promise<void>,
    logout: () => void,
    isWhitelisted: () => boolean,
    user: IUser,
    loaded: boolean,
    getLoggedInUser: () => Promise<void>
}

type IUser = {
    avatar: string,
    display: string,
    id: number,
    username: string,
}

const authContext = createContext<IauthContext>({} as IauthContext);

const openTwitchAuth = () => {
    const w = 505;
    const h = 860;
    const y = window.top!.outerHeight / 2 + window.top!.screenY - (h / 2);
    const x = window.top!.outerWidth / 2 + window.top!.screenX - (w / 2);

    const win = window.open(
        `https://beta.knucklebones.tv/auth/login`,
        'AuthWindow',
        `toolbar=no,location=no,directories=no,status=no,menubar=no,resizable=np,copyhistory=no,width=${w},height=${h},top=${y},left=${x}`
    );

    const timer = setInterval(() => {
        if (win!.closed) {
            clearInterval(timer);
        }
    }, 500);
}

export const useAuth = () => {
    return useContext(authContext);
}

const IsLocalDev: boolean = process.env.NODE_ENV === 'development';

const useProvideAuth = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState({} as IUser);
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();
    const { connect, disconnect } = useSocket();

    const logout = useCallback(() => {
        setUser({} as IUser);
        setAuthenticated(false);
        if (!IsLocalDev) {
            disconnect();
            axios('https://beta.knucklebones.tv/auth/logout');
        }
    }, [disconnect]);

    const isWhitelisted = useCallback(() => {
        const whitelist = [
            'sublimnl',
        ]

        return (authenticated && whitelist.indexOf(user.username) > -1) || IsLocalDev
    }, [authenticated, user]);

    const login = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            if (IsLocalDev) {
                setUser({
                    avatar: "https://static-cdn.jtvnw.net/jtv_user_pictures/6d345d45-dec3-4084-b370-82f273864e99-profile_image-300x300.png",
                    display: "sublimnl (DEV)",
                    username: "sublimnl",
                    id: 1,
                } as IUser);
                setAuthenticated(true);
                navigate("/dashboard");
                /*
                sendJsonMessage({
                    sess: getCookie("sess"),
                })
                */
                return resolve();
            }

            openTwitchAuth();

            const eventMethod: string = (window as { [key: string]: any }).addEventListener ? "addEventListener" : "attachEvent";
            const removeMethod: string = (window as { [key: string]: any }).removeEventListener ? "removeEventListener" : "detachEvent";
            const messageEvent: string = eventMethod === "attachEvent" ? "onmessage" : "message";

            (window as { [key: string]: any })[eventMethod](messageEvent, (ev: MessageEvent) => {
                if (
                    ev.origin === `https://${window.location.hostname}` ||
                    ev.origin === `https://beta.knucklebones.tv`
                ) {
                    if ('login' in ev.data && ev.data['login'] === 'ok') {
                        setUser(ev.data['user']);
                        setAuthenticated(true);
                        connect();
                        /*
                        sendJsonMessage({
                            sess: getCookie("sess"),
                        })*/
                        navigate("/dashboard");
                        return resolve();
                    }
                } else {
                    console.log('Access violation');
                    return reject("Access violation.")
                }
                (window as { [key: string]: any })[removeMethod](messageEvent, this);
                reject("Unknown response.");
            });
        });
    }, [connect, navigate]);

    const getLoggedInUser = async () => {
        if (IsLocalDev) {
            setUser({
                avatar: "https://static-cdn.jtvnw.net/jtv_user_pictures/6d345d45-dec3-4084-b370-82f273864e99-profile_image-300x300.png",
                display: "sublimnl (DEV)",
                username: "sublimnl",
                id: 1,
            } as IUser);
            setAuthenticated(true);
            setLoaded(true);
            return;
        }

        try {
            const { data } = await axios(
                '/api/me',
            );

            if (data.status === 200) {
                setUser(data.response);
                setAuthenticated(true);
                connect();
            }
            setLoaded(true);
        } catch (err) {
            console.log(err)
        }
    }

    return {
        authenticated,
        login,
        logout,
        isWhitelisted,
        loaded,
        user,
        getLoggedInUser,
    };
}

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const auth = useProvideAuth();

    useEffect(() => {
        auth.getLoggedInUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <authContext.Provider value={auth}>
            {auth.loaded ? children :
                <Dimmer active>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
            }
        </authContext.Provider>
    );
}
