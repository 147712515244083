import React from 'react';
import './App.css';
import PageRouter from './components/PageRouter';

function App() {
  return (
        <PageRouter />
  )
}

export default App;
