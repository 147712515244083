import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownProps,
    Grid,
    Segment,
} from 'semantic-ui-react';
import { ChromePicker, ColorResult } from 'react-color';

import { diceThemes } from './diceThemes';
import DicePreview from './DicePreview';


function SettingsKnucklebones() {
    const [ diceSettingsDirty, setDiceSettingsDirty ] = useState(false);
    const [theme, setTheme] = useState('cotl');
    const [themeColor, setThemeColor] = useState('#FF0000');
    const [themeCanColorize, setThemeCanColorize] = useState(false);
    const [useOpponentTheme, setUseOpponentTheme] = useState(true);

    const handleThemeChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const index = data!.options!.findIndex((option) => {
            return option!.value === data!.value
        });

        setTheme(data.value as string)
        setThemeCanColorize('themeable' in data!.options![index]);
        setDiceSettingsDirty(true);
    };

    const handleThemeColorChange = (color: ColorResult) => {
        setThemeColor(color.hex);
        setDiceSettingsDirty(true);
    };

    const handleUseOpponentThemeChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setUseOpponentTheme(data.checked as boolean);
        setDiceSettingsDirty(true);
    }

    return (
        <>
            <h3>Dice</h3>
            <p>Select the appearance of your dice. Some dice themes allow customizing the color pallete of the dice.</p>
            <Segment inverted raised attached>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <strong>Theme</strong>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Dropdown selection onChange={handleThemeChange} options={diceThemes} defaultValue={theme} />
                        </Grid.Column>
                    </Grid.Row>
                    {themeCanColorize ?
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <strong>Theme Color</strong>
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <ChromePicker
                                    color={themeColor}
                                    disableAlpha={true}
                                    onChangeComplete={handleThemeColorChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    :
                        ''
                    }
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <strong>Preview</strong>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <div style={{ width: '100%', height: 250, border: '1px solid #E3DAC9', borderRadius: 5 }}>
                                <DicePreview theme={theme} color={themeColor} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <strong>Use Opponents Dice Theme</strong>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <Checkbox toggle checked={useOpponentTheme} onChange={handleUseOpponentThemeChange} /><br />
                            When turned on, matches will use the opponents dice theme of choice. By turning this setting off, all matches will use your selected theme in your game screen.
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment inverted attached='bottom'>
                <Button floated='right' disabled={!diceSettingsDirty}>Save Changes</Button>
                <br /><br />
            </Segment>
        </>
    )
}

export default SettingsKnucklebones;
