import React from "react";
import {
    Button,
    Dropdown,
    Icon,
    Image,
} from "semantic-ui-react";
import { useAuth } from "../../providers/AuthProvider";
import { NavLink } from "react-router-dom";

const LoginButton: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { authenticated, user, login, logout } = useAuth();

    const handleLogin = async () => {
        await login();
    };

    const trigger = (
        <span>
            <Image avatar src={user?.avatar} /> {user?.display}
        </span>
    )

    return (
        <div style={{ margin: 'auto', color: '#FFF' }}>
            {authenticated ?
                <Dropdown
                    selectOnNavigation={false}
                    trigger={trigger}
                >
                    <Dropdown.Menu>
                        <Dropdown.Item as={NavLink} to='/dashboard'>Play Knucklebones</Dropdown.Item>
                        <Dropdown.Item as={NavLink} to='/settings'>Account Settings</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={logout}>Sign Out</Dropdown.Item>
                    </Dropdown.Menu>

                </Dropdown>
                :
                <Button color="purple" style={{ backgroundColor: 'rgb(100, 65, 165)' }} onClick={handleLogin}>
                    <Icon name='twitch' />
                    Login with Twitch
                </Button>
            }
        </div>
    );
}

export default LoginButton;