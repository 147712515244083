import { useCallback, useEffect, useState } from 'react';
import useWebSocket, { ReadyState as ruwReadyState } from 'react-use-websocket';
import Knucklebones, { TwitchUser } from '../lib/knucklebones';

//const SOCKET_URL = 'wss://' + window.location.hostname + '/ws';
const SOCKET_URL = 'wss://beta.knucklebones.tv/ws';
let started = false;

interface JsonEvent {
    event: string,
    data: Object,
    user: TwitchUser,
}

export const ReadyState = ruwReadyState;

export function useSocket() {
    const [isOpen, setIsOpen] = useState(false);

    const {
        lastJsonMessage,
        sendJsonMessage,
        readyState,
    } = useWebSocket(SOCKET_URL, {
        share: true,
        shouldReconnect: () => true,
    }, isOpen);

    const sendPing = useCallback(() => {
        if (isOpen && readyState === ruwReadyState.OPEN) {
            sendJsonMessage({
                "event": "ping",
            }, false);
        }

        setTimeout(sendPing, 30000);
    }, [isOpen, readyState, sendJsonMessage]);

    const connect = () => {
        setIsOpen(true);
    }

    const disconnect = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if (!started && readyState === ruwReadyState.OPEN) {
            sendPing();
            started = true;
        }
    }, [readyState, sendPing]);

    useEffect(() => {
            if (lastJsonMessage !== null) {
                const { event, user }: JsonEvent = lastJsonMessage as unknown as JsonEvent;

                switch (event) {
                    case "challenge":
                        Knucklebones.getInstance().ShowInvite(user);
                }
                console.log(lastJsonMessage, event, user);
            }
        }, [lastJsonMessage, sendJsonMessage]
    );

    return {
        connect,
        disconnect,
        sendJsonMessage,
        state: readyState,
    }
}
