import React from 'react';
import {
  Container,
  Image,
  Segment,
} from 'semantic-ui-react'
import { useDiceTray } from 'react-dicetray';

function HomePage() {
  const heroDiceTray = useDiceTray({
    scale: 6,
    theme: "cotl",
    gravity: 5,
    throwForce: 3
  });

  return (
    <>
      <div style={{ position: 'relative', left: 0, right: 0, width: '100%', height: '620px', display: 'contents', verticalAlign: 'middle' }}>
        <Image src='/images/hero.png' style={{ objectFit: 'cover', height: '620px', minWidth: '100%' }} />
        <div style={{ background: 'linear-gradient(180deg, rgba(32,30,42,0.74) 0%, rgba(32,30,42,0.84) 60%, rgba(32,30,42,1) 90%)', zIndex: 0, position: 'absolute', inset: '0px', height: '670px' }} />
        <div style={{ position: 'absolute', top: '150px', left: 0, right: 0, color: '#fff', display: 'inline-block', height: '200px' }}>
          <h1 style={{ fontSize: '62px', textAlign: 'center' }} className='rattle'>knucklebones.tv</h1>
        </div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div style={{ position: 'absolute', left: 0, top: 50, height: '600px', width: '100%' }} onClick={() => heroDiceTray.rollDice('2d6')} onTouchEnd={() => heroDiceTray.rollDice('2d6')}>
          {heroDiceTray.diceTray}
        </div>
      </div>
      <Container id="about" style={{ scrollMarginTop: 75 }}>
        <Segment inverted>
          <h1>About</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </Segment>
      </Container>
      <br /><br />
      <Container id="how-to-play" style={{ scrollMarginTop: 75 }}>
        <Segment inverted>
          <h1>How To Play</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Justo eget magna fermentum iaculis eu non diam phasellus. Hendrerit dolor magna eget est lorem ipsum dolor sit amet. Varius vel pharetra vel turpis nunc eget lorem dolor. Ipsum faucibus vitae aliquet nec ullamcorper sit amet risus. Viverra suspendisse potenti nullam ac tortor vitae purus faucibus. Est ullamcorper eget nulla facilisi etiam dignissim diam quis enim. Tellus orci ac auctor augue mauris augue neque gravida in. Faucibus nisl tincidunt eget nullam non. Feugiat nibh sed pulvinar proin gravida hendrerit. Egestas dui id ornare arcu odio ut sem nulla pharetra. Pharetra convallis posuere morbi leo urna molestie at. At lectus urna duis convallis convallis tellus id interdum. Sed viverra tellus in hac habitasse platea dictumst. Fermentum odio eu feugiat pretium nibh ipsum consequat. Malesuada nunc vel risus commodo viverra maecenas accumsan. Consectetur adipiscing elit duis tristique. Sagittis vitae et leo duis ut diam quam nulla porttitor. Arcu bibendum at varius vel. Non pulvinar neque laoreet suspendisse.
          </p>
          <p>
            Porta nibh venenatis cras sed felis eget velit. Metus vulputate eu scelerisque felis imperdiet. Pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Et odio pellentesque diam volutpat commodo. Id porta nibh venenatis cras sed felis. Id velit ut tortor pretium viverra. Id nibh tortor id aliquet. Ac tortor dignissim convallis aenean et tortor. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Tellus cras adipiscing enim eu turpis egestas pretium aenean. Sed velit dignissim sodales ut. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien.
          </p>
          <p>
            Volutpat sed cras ornare arcu dui vivamus arcu felis. Nulla aliquet enim tortor at auctor urna nunc id cursus. Dui sapien eget mi proin sed libero enim sed faucibus. Tellus in metus vulputate eu scelerisque felis imperdiet. Varius vel pharetra vel turpis. Maecenas sed enim ut sem viverra aliquet eget sit amet. Fermentum et sollicitudin ac orci phasellus. Augue ut lectus arcu bibendum at varius vel pharetra vel. Arcu bibendum at varius vel pharetra vel. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Condimentum lacinia quis vel eros donec ac odio tempor orci. Orci phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. Elit eget gravida cum sociis. Sapien et ligula ullamcorper malesuada.
          </p>
          <p>
            Non curabitur gravida arcu ac tortor dignissim convallis aenean. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Elementum nibh tellus molestie nunc. Amet porttitor eget dolor morbi non. Adipiscing bibendum est ultricies integer quis auctor elit sed. Tincidunt id aliquet risus feugiat in ante metus. Ullamcorper sit amet risus nullam. Consequat interdum varius sit amet mattis vulputate enim nulla. Consequat mauris nunc congue nisi vitae suscipit tellus mauris a. Neque laoreet suspendisse interdum consectetur libero. Ultricies mi quis hendrerit dolor magna. Eu mi bibendum neque egestas congue quisque egestas.
          </p>
          <p>
            Et odio pellentesque diam volutpat commodo sed egestas egestas fringilla. Molestie at elementum eu facilisis sed. Tempus iaculis urna id volutpat. Accumsan sit amet nulla facilisi morbi tempus iaculis. Nam at lectus urna duis convallis convallis. Dignissim cras tincidunt lobortis feugiat vivamus at augue eget arcu. Sit amet facilisis magna etiam tempor orci eu lobortis elementum. Malesuada pellentesque elit eget gravida. Duis tristique sollicitudin nibh sit amet commodo nulla facilisi nullam. Vitae purus faucibus ornare suspendisse sed.
          </p>
        </Segment>
      </Container>
    </>
  )
}

export default HomePage;
