import React from "react";
import { Icon, Menu, MenuItemProps, Sidebar } from "semantic-ui-react";

interface Props extends React.PropsWithChildren {
    menuItems: Array<MenuItemProps>,
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>,
    visible: boolean
}

const NavBarMobile: React.FC<Props> = props => {
    const {
        children,
        menuItems,
        setVisibility,
        visible
    } = props;

    return (
        <Sidebar.Pushable>
            <Sidebar
                as={Menu}
                animation="overlay"
                direction="right"
                icon="labeled"
                inverted
                items={menuItems}
                onHide={() => setVisibility(false)}
                vertical
                visible={visible}
            />
            <Sidebar.Pusher
                dimmed={visible}
                style={{ minHeight: "100vh" }}
            >
                <Menu fixed="top" inverted>
                    <Menu.Item as='a' href='/' header>
                        knucklebones.tv
                    </Menu.Item>
                    <Menu.Item position='right' onClick={() => setVisibility(true)}>
                        <Icon name="sidebar" />
                    </Menu.Item>
                </Menu>
                {children}
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
};

export default NavBarMobile;