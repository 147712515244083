import React from 'react';
import SettingsKnucklebones from './Settings/Knucklebones';
import {
    Container,
    Tab,
} from 'semantic-ui-react'
import SettingsNotifications from './Settings/Notifications';
import SettingsPrivacy from './Settings/Privacy';

function Settings() {
    const panes = [
        { menuItem: 'Game', render: () => <Tab.Pane as={Container}><SettingsKnucklebones /></Tab.Pane> },
        { menuItem: 'Notifications', render: () => <Tab.Pane as={Container}><SettingsNotifications /></Tab.Pane> },
        { menuItem: 'Privacy', render: () => <Tab.Pane as={Container}><SettingsPrivacy /></Tab.Pane> },
    ];

    return (
        <>
            <Container style={{ marginTop: 100 }}>
                <h1>Settings</h1>
                <Tab menu={{ secondary: true, pointing: true, inverted: true }} panes={panes} />
            </Container>
        </>
    )
}

export default Settings;
