import React from "react";
import {
    Container,
    Image,
    Menu,
    MenuItemProps
} from "semantic-ui-react";
import useScrollPosition from '@react-hook/window-scroll';
import LoginButton from "./LoginButton";

interface Props extends React.PropsWithChildren {
    menuItems: Array<MenuItemProps>,
}

const NavBarDesktop: React.FC<Props> = props => {
    const scrollY = useScrollPosition(60 /*fps*/)
    const { menuItems } = props;

    return (
        <>
            <Menu fixed="top" inverted style={{ backgroundColor: scrollY < 100 ? "rgba(34, 38, 57, 0)" : "rgb(34, 38, 57, 0.9)" }}>
                <Container>
                    <Image src="/logo192.png" />
                        {menuItems.map((item) => (
                            <Menu.Item {...item} />
                        ))}
                    <Menu.Menu position="right">
                        <LoginButton />
                    </Menu.Menu>
                </Container>
            </Menu>
        </>
    );
};

export default NavBarDesktop;